<template>
  <b-container>

    <div v-if="items.length === 0" class="text-center my-5">
      <MTBLoader  />
    </div>

    <!-- <h3 class="muted">Research Reports</h3> -->

    <!-- <b-row class="justify-content-center mt-3">
      <b-col md="10">
        <h1 class="text-primary mb-3">Investigating Innovation Ecosystems</h1>
      </b-col>
    </b-row> -->


    <!-- MAIN REPORTS LARGE -->
    <div  v-if="items.length !== 0">
      <div v-for="(item, index) in mainReports" :key="index" id="banner-image">
      <!-- <div v-for="(item, index) in currentReports" :key="index" id="banner-image"> -->

        
        <b-row class="mt-4 mb-5">
          <b-col>
            <b-img class="shadow-sm" style="min-height:38vh; border-radius: 25px; filter: brightness(0.8);" fluid-grow :src="item['banner']"></b-img>
            <div class="title-banner">
              <h1 style="font-size: calc(10px + 2vw);">{{ item["title"].split("-")[0] }}</h1>
              <h3 style="font-size: calc(8px + 1vw);">{{ item["title"].split("-")[1] }}</h3>
              <b-button class="mt-3 mx-2" size="sm" :to="{ name: 'Report', params: { name: item.slug } }" pill variant="primary"><v-icon class="mx-2"  name="download"  scale="1" />Download Report</b-button>
              <b-button v-if="item.type == 'digital'" class="mt-3 mx-2" size="sm" :to="{ name: item.slug }" target="_blank" pill variant="primary"><v-icon class="mr-2"  name="search"  scale="1" />Explore Report</b-button>
              <b-button v-if="item.directory" class="mt-3 mx-2" size="sm" :to="{ name: 'Directory', params: { name: item.directorySlug } }"  pill variant="primary"><v-icon class="mr-2"  name="search"  scale="1" />Explore Directory</b-button>
            </div>
          </b-col>
        </b-row>
      </div>

    <!-- MAIN REPORTS SMALL -->
    <div style="margin-bottom:80px;"  v-if="items.length !== 0">
      <div >
        <b-row class="mt-4 mb-5">
          <b-col class="mt-4 mb-3" md="6" v-for="(item, index) in currentReports" :key="index" id="banner-image">
            <b-img class="shadow-sm" style="min-height:38vh; object-fit: cover; border-radius: 25px; filter: brightness(0.9);" fluid-grow :src="item['banner']"></b-img>
            <div class="title-banner-small">
              <h1 style="font-size: calc(12px + 1vw);">{{ item["title"].split("-")[0] }}</h1>
              <h3 style="font-size: calc(6px + 0.8vw);">{{ item["title"].split("-")[1] }}</h3>
              <b-button class="mt-3 mx-2" size="sm" :to="{ name: 'Report', params: { name: item.slug } }" pill variant="primary"><v-icon class="mx-2"  name="download"  scale="1" />Download Report</b-button>
              <b-button v-if="item.type == 'digital'" class="mt-3 mx-2" size="sm" :to="{ name: item.slug }" target="_blank" pill variant="primary"><v-icon class="mr-2"  name="search"  scale="1" />Explore Report</b-button>
              <b-button v-if="item.directory" class="mt-3 mx-2" size="sm" :to="{ name: 'Directory', params: { name: item.directorySlug } }"  pill variant="primary"><v-icon class="mr-2"  name="search"  scale="1" />Explore Directory</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- REPORT CATEGORIES -->
    <ReportsGridPreview :items="scaleupsCategory.slice(0, 9)" category="scaleups" categoryTitle="Startups & Scaleups"  subtitle="With the support of the European Commission and local partners, Mind the Bridge regularly publishes dedicated reports to analyse and compare global tech scaleup ecosystems, covering Europe, US and Silicon Valley, South Korea, the MENA region, China, and several others." />
    <ReportsGridPreview :items="outpostsCategory.slice(0, 6)" category="outposts" categoryTitle="Innovation Outposts" subtitle="Mind the Bridge regularly maps the innovation presence (antennas, innovation labs, R&D centers, CVCs) of large international corporations and trade organisations in the most thriving global innovation hubs, including Silicon Valley, Israel, and South Korea." />
    <ReportsGridPreview :items="openinnovationCategory.slice(0, 3)" category="open-innovation" categoryTitle="Open Innovation" subtitle="Leveraging its decade-long experience in innovation advisory, Mind the Bridge regularly publishes reports covering and analysing current trends and scenarios in corporate-startup collaboration at global scale. Reports are also produced under the framework of Corporate Startup Stars." />
    <ReportsGridPreview :items="policyCategory.slice(0, 3)"  category="policy" categoryTitle="Policy" subtitle="Mind the Bridge/SEP Policy Reports and Briefs are aimed at providing data and recommendations to support policies about fostering innovation at local and regional level, GDPR and data regulation, EU digital single market, and scaling startup ecosystems." />
    <ReportsGridPreview :items="maipoCategory.slice(0, 3)" category="ma-ipo" categoryTitle="Startup M&As" subtitle="Mind the Bridge, in partnership with Crunchbase and with the support of local partners, produces data and analyses about startup acquisitions at global scale and dedicated industry M&A reports." />

    </div>

      
  </b-container>
</template>

<script>
import MTBLoader from "../components/MTBLoader";
import ReportsGridPreview from "../components/ReportsGridPreview";

import util from "../support/util";

export default {
  data() {
    return {};
  },
  components: { MTBLoader,ReportsGridPreview },
  created(){
    util.scrollTop()
  },
  computed: {
    items() {
      return this.$store.state.reports;
    },
    currentReports(){
      return this.$store.state.reports.filter((item)=>{
        return item.current && !item.main && !item.draft
      })
    },
    mainReports(){
      return this.$store.state.reports.filter((item)=>{
        // return item.main
        return item.main && !item.draft
      })
    },
    directories(){
      return this.$store.state.directories
    },
    scaleupsCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "scaleups" && !item.draft
      })
    },
    scaleupsCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "scaleups" && !item.draft
      })
    },
    policyCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "policy" && !item.draft
      })
    },
    outpostsCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "outposts" && !item.draft
      })
    },
    openinnovationCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "open-innovation" && !item.draft
      })
    },
    maipoCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "ma-ipo" && !item.draft
      })
    },
  },
};
</script>

<style scoped>
.title-banner {
  z-index: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.title-banner-small {
  z-index: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#banner-image:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}

#banner-image{
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  min-height: 300px;
}
#banner-image::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
</style>
